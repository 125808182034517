import store from '@/store'

import {
  request,
  confPageRequest,
  confApiRequest,
  confTableRequest,
  confColumnRequest
} from '@/api'

const toHump = (name) => {
  // eslint-disable-next-line no-useless-escape
  return name.replace(/\_(\w)/g, function(all, letter) {
    return letter.toUpperCase()
  })
}

async function dealOptionalData (item, ct) {
  item.conf = item.conf ? JSON.parse(item.conf) : null
  let key = ct.tableName + '.' + item.columnName + '.optionalValue'
  if (item.optionalValue) {
    store.dispatch('initOptionalData', {
      key,
      dataList: JSON.parse(item.optionalValue)
    })
    item.getOptionalValueKey = key
  } else if (item.mainConfTableId && item.mainId) {
    let p = {
      type: 'get_data',
      dataConf: item.mainConfTableId
    }
    let api = await confApiRequest.get(p)
    if (api.length > 0) {
      api = api[0]
      let confTable = await confTableRequest.get({id: api.dataConf})
      confTable = confTable[0]
      let confColumn = await confColumnRequest.get({confTableId: confTable.id})
      confColumn.forEach((v) => {
        v.conf = v.conf ? JSON.parse(v.conf) : null
      })
      let keyColumnName = confColumn.find(v => v.id === item.mainId)
      keyColumnName = keyColumnName.columnName
      let labelColumnName = confColumn.filter(v => v.conf && v.conf.isLabel)
      let getData = async function () {
        let dataList = await request(api.path, api.method)
        let newData = dataList.map((v) => {
          return {
            key: v[keyColumnName],
            label: labelColumnName.map((v2) => {
              return v[toHump(v2.columnName)]
            }).join('_'),
            data: v
          }
        })
        return newData
      }
      key = confTable.tableName + '.' + keyColumnName + '.optionalValue'
      store.dispatch('initOptionalData', {
        key,
        getData
      })
      item.getOptionalValueKey = key
    }
  }
}

async function loadApiById (parm) {
  let data = await confApiRequest.get(parm)
  data = data[0]
  data.parm = data.parm ? JSON.parse(data.parm) : []
  if (data.dataConf && !data.confTable) {
    data.confTable = await confTableRequest.get({id: data.dataConf})
    data.confTable = data.confTable[0]
    data.confTable.confColumn = await confColumnRequest.get({confTableId: data.confTable.id})
    let i = 0
    while (i < data.confTable.confColumn.length) {
      dealOptionalData(data.confTable.confColumn[i], data.confTable)
      i += 1
    }
  }
  return data
}

export async function loadPageConfig (parm) {
  let pageData = await confPageRequest.get(parm)
  pageData = pageData[0]
  pageData.conf = pageData.conf ? JSON.parse(pageData.conf) : null
  if (pageData.conf) {
    if (pageData.conf.loadData.apiId && !pageData.conf.loadData.api) {
      pageData.conf.loadData.api = await loadApiById({id: pageData.conf.loadData.apiId})
    }
    let i = 0
    while (i < pageData.conf.pageMenu.length) {
      let item = pageData.conf.pageMenu[i]
      if (item.apiId && !item.api) {
        item.api = await loadApiById({id: item.apiId})
      }
      i += 1
    }
    i = 0
    while (i < pageData.conf.dataDmenu.length) {
      if (pageData.conf.dataDmenu[i].apiId && !pageData.conf.dataDmenu[i].api) {
        pageData.conf.dataDmenu[i].api = await loadApiById({id: pageData.conf.dataDmenu[i].apiId})
      }
      i += 1
    }
  }
  return pageData
}
