<template>
  <div class="sign-page" v-if="!initIng">
    <div class="sign-page-div">
      <fm-search-group class="sign-page-top-2" :search-parms="searchParms" @clickSearch="loadData" v-if="searchParms"></fm-search-group>
      <div class="sign-page-content content-block">
        <div class="content-top">
          <fm-title :title-text="pageConfig.title" :note-text="noteText" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
            <slot name="title"></slot>
          </fm-title>
        </div>
        <div class="content-body">
          <fm-table
            :column-list="columnList"
            :data-list="dataList"
            :show-checkbox="showCheckbox"
            @checkDataChange="checkDataChange"
            @countDataChange="countDataChange"
            :count-parm="countParm"
            :count-fun="countFun"
            @tableAction="tableAction"
            :table-actions="tableActions"
            :show-search="true">
          </fm-table>
        </div>
      </div>
    </div>
    <fm-form-dialog
      :form-title="formConf.title"
      :open-dialog.sync="formConf.openDialog"
      :form-parms="formConf.formParms"
      :old-data="formConf.hisData"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="formHandleClose">
    </fm-form-dialog>
  </div>
</template>

<script>
import {
  dealPageConfig
} from '@/test/norm_page'

import {
  loadPageConfig
} from '@/test/test'

export default {
  props: {
    id: {
      type: String
    }
  },
  watch: {
    id () {
      this.initPage()
    }
  },
  data () {
    return {
      initIng: true,
      fileParm: {},
      countParm: {},
      searchData: {},
      showCheckbox: false,
      dataList: [],
      noteText: '',
      formConf: {
        hisData: null,
        formParms: [],
        openDialog: false,
        toDo: null
      },
      menu: null,
      pageConfig: {
        title: null,
        loadDataRequest: null,
        columnList: [{
          field: 'id',
          title: 'id'
        }],
        titleMenus: [],
        tableActions: [],
        searchParms: [],
        getSearchParms: null,
        fileParm: {},
        countParm: [],
        showCheckbox: false
      }
    }
  },
  computed: {
    titleMenus () {
      return this.pageConfig.titleMenus
    },
    tableActions () {
      return this.pageConfig.tableMenus
    },
    searchParms () {
      return this.pageConfig.getSearchParms ? this.pageConfig.getSearchParms(this.$store.getters) : this.pageConfig.searchParms
    },
    columnList () {
      return this.pageConfig.columnList
    }
  },
  async created () {
    this.initPage()
  },
  methods: {
    async initPage () {
      this.initIng = true
      let data = await loadPageConfig({id: this.id})
      this.pageConfig = await dealPageConfig(data)
      window.pageConfig = this.pageConfig
      this.initIng = false
      this.loadData()
    },
    async loadData (parm) {
      this.searchData = parm || this.searchData
      this.dataList = await this.pageConfig.loadDataRequest(this.searchData)
    },
    countFun () {},
    async formSubmit (data) {
      if (this.formConf.toDo.request) {
        await this.formConf.toDo.request(data)
        this.formConf.openDialog = false
        this.loadData()
      }
    },
    formHandleClose () {
      this.formConf.openDialog = false
    },
    countDataChange (parm) {
      this.$emit('countDataChange', parm)
    },
    async clickTitleMenu (parm) {
      this.chooseData = null
      this.menu = this.titleMenus.find(v => v.key === parm)
      if (this.menu && this.menu.formConf) {
        this.formConf.hisData = null
        this.formConf.formParms = await this.menu.formConf.getFormParms()
        this.formConf.toDo = this.menu.formConf.toDo
        this.formConf.openDialog = true
      }
    },
    async tableAction (parm) {
      // this.chooseData = parm.data
      this.chooseData = null
      this.menu = this.tableActions.find(v => v.key === parm.action)
      if (this.menu && this.menu.formConf) {
        this.formConf.hisData = parm.data
        this.formConf.formParms = await this.menu.formConf.getFormParms()
        this.formConf.toDo = this.menu.formConf.toDo
        this.formConf.openDialog = true
      } else if (this.menu && this.menu.dealFun) {
        let r = await this.menu.dealFun(this, parm.data)
        if (r === 'reloadData') {
          this.loadData()
        }
      }
    },
    currentPageChange (parm) {
      this.$emit('currentPageChange', parm)
    },
    checkDataChange (parm) {
      this.$emit('checkDataChange', parm)
    }
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-page-div {
  margin: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sign-page-div-no-padding {
  margin: 0;
}
.sign-page {
  width: 100%;
  height: 100%;
  display: flex;
}
.sign-page-top-1 {
  margin-bottom: 1rem;
}
.sign-page-top-2 {
  margin-bottom: 1.25rem;
}
.sign-page-content {
  flex: 1;
}
.content-body {
  flex: 1;
}
</style>
